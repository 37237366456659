import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  } else {
    return originalPush.call(this, location).catch(err => err);
  }
}

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  } else {
    return originalReplace.call(this, location).catch(err => err);
  }
}

const routes = [
  { path: "/lottery", name: "Lottery", component: () => import("../views/Lottery") },
  { path: "/award/notes", name: "AwardNotes", component: () => import("../pages/AwardNotes") },
  { path: "/register", name: "Register", component: () => import("../views/Register") },
  { path: "/addresses", name: "Addresses", component: () => import("../pages/Addresses") },
  { path: "/address/add", name: "AddressAdd", component: () => import("../pages/AddressAdd") },
  { path: "/address/edit", name: "AddressEdit", component: () => import("../pages/AddressEdit") }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
