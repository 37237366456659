import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import layer from "vue-layer"
import init from "./common/init";
import "vue-layer/lib/vue-layer.css"

Vue.config.productionTip = false
Vue.prototype.$layer = layer(Vue)

router.afterEach(() => {
  window.scrollTo(0, 0);
})

init.initData(function() {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
})
