<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
document.documentElement.style.fontSize = document.documentElement.clientWidth / 3.75 + "px";

export default {

}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

button:focus,
textarea:focus,
select:focus {
  outline: none;
}

input {
  outline: none;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
.cursor-el {
  cursor: pointer;
}

a {
  color: #2c3e50;
  text-decoration: none;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #2c3e50;
}

.text-c {
  text-align: center;
}

.default-bg-color {
  background-color: #207ab7;
}

.success-txt {
  color: #5eb95e;
}

.red-txt {
  color: #ea3535;
}

.err-tips {
  color: red;
}

.ellipsis-content-div {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
