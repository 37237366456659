import store from "../store/index.js";

export function initData(callback) {
    try {
        let userAgent = navigator.userAgent.toLowerCase();
        if(userAgent.indexOf('wjt_app') > -1){
            let token = window.android.getToken();
            store.commit("setToken", token);
        }
        callback();
    } catch (e) {
        alert("页面初始化失败，请稍后重试");
        window.android.goBack();
    }
}

export default {
    initData
};
